'use client';

import { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';

import Icon from './Icon';
import Button, { buttonStyle } from './NewButton';
import { UserContext } from '../lib/UserContext';
import MinicastleLogo from '../public/Minicastle_Logo.svg';

export const StyledDropdownRoot = styled(RadixDropdownMenu.Root)`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
`;

const StyledDropdownTrigger = styled(RadixDropdownMenu.Trigger)`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  color: white;
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const StyledDropdownSubTrigger = styled(RadixDropdownMenu.SubTrigger)`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  color: rgba(0, 0, 0, 0.95);
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StyledDropdownContent = styled(RadixDropdownMenu.Content)`
  background-color: white;
  right: 0;
  padding: 0.25rem;
  border-radius: 4px;
  width: 10rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
`;

const StyledDropdownSubContent = styled(RadixDropdownMenu.SubContent)`
  background-color: white;
  right: 0;
  padding: 0.25rem;
  padding-right: 0.5rem;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
`;

const StyledDropdownItem = styled(RadixDropdownMenu.Item)`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.95);
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StyledNav = styled.nav`
  background-color: #212121;
  color: #ffe76b;
  padding: 8px 16px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1080px;
    margin: 0 auto;
  }
`;

const Nav = () => {
  const { user, logout } = useContext(UserContext);
  const router = useRouter();

  return (
    <StyledNav>
      <div className="container">
        <Link href="/d">
          <Image src={MinicastleLogo} height={24} alt={'Minicastle Logo'} />
        </Link>
        {user ? (
          <StyledDropdownRoot>
            <StyledDropdownTrigger
              className={buttonStyle({ type: 'tertiary' })}
            >
              <Icon icon="menu" size={14} color="white" /> <span>Menu</span>
            </StyledDropdownTrigger>
            <RadixDropdownMenu.Portal>
              <StyledDropdownContent align="end" sideOffset={4}>
                <Link href="/d" passHref style={{ textDecoration: 'none' }}>
                  <StyledDropdownItem as="a">Dashboard</StyledDropdownItem>
                </Link>
                <Link
                  href="/calendar"
                  passHref
                  style={{ textDecoration: 'none' }}
                >
                  <StyledDropdownItem as="a">Calendar</StyledDropdownItem>
                </Link>
                <Link
                  href="/profile"
                  passHref
                  style={{ textDecoration: 'none' }}
                >
                  <StyledDropdownItem as="a">Profile</StyledDropdownItem>
                </Link>
                <Link
                  href="/guides/homeowners-guide"
                  style={{ textDecoration: 'none' }}
                >
                  <StyledDropdownItem as="a">Guides</StyledDropdownItem>
                </Link>
                <RadixDropdownMenu.Sub>
                  <StyledDropdownSubTrigger
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    Help
                    <Icon icon="arrow-right" size={14} color={'gray'} />
                  </StyledDropdownSubTrigger>
                  <RadixDropdownMenu.Portal>
                    <StyledDropdownSubContent>
                      <Link
                        className="plausible-event-name=Docs+Nav+Click"
                        href="https://docs.minicastle.app"
                        target="_blank"
                        passHref
                        style={{ textDecoration: 'none' }}
                      >
                        <StyledDropdownItem as="a">
                          Documentation
                          <Icon icon="open" size={14} color={'gray'} />
                        </StyledDropdownItem>
                      </Link>
                      <Link
                        className="plausible-event-name=Roadmap+Nav+Click"
                        href="https://minicastle.canny.io/"
                        target="_blank"
                        passHref
                        style={{ textDecoration: 'none' }}
                      >
                        <StyledDropdownItem as="a">
                          Roadmap
                          <Icon icon="open" size={14} color={'gray'} />
                        </StyledDropdownItem>
                      </Link>
                      <Link
                        href="/about"
                        passHref
                        style={{ textDecoration: 'none' }}
                      >
                        <StyledDropdownItem as="a">
                          About Minicastle
                        </StyledDropdownItem>
                      </Link>
                    </StyledDropdownSubContent>
                  </RadixDropdownMenu.Portal>
                </RadixDropdownMenu.Sub>
                <Link
                  href="https://minicastle.canny.io/changelog"
                  target="_blank"
                  passHref
                  style={{ textDecoration: 'none' }}
                  className="plausible-event-name=Changelog+Nav+Click"
                >
                  <StyledDropdownItem>
                    What's New?
                    <Icon icon="open" size={14} color={'gray'} />
                  </StyledDropdownItem>
                </Link>
                <Link
                  href="https://senja.io/p/minicastle/r/GxajJu"
                  target="_blank"
                  passHref
                  style={{ textDecoration: 'none' }}
                >
                  <StyledDropdownItem>
                    Leave Feedback
                    <Icon icon="open" size={14} color={'gray'} />
                  </StyledDropdownItem>
                </Link>
                <Link
                  href="https://buymeacoffee.com/minicastle"
                  target="_blank"
                  passHref
                  style={{ textDecoration: 'none' }}
                >
                  <StyledDropdownItem>
                    🍕 Buy A Pizza
                    <Icon icon="open" size={14} color={'gray'} />
                  </StyledDropdownItem>
                </Link>
                <StyledDropdownItem
                  onClick={async () => {
                    console.log('Logging out...');
                    logout();
                    router.push('/login');
                  }}
                >
                  Sign Out
                </StyledDropdownItem>
              </StyledDropdownContent>
            </RadixDropdownMenu.Portal>
          </StyledDropdownRoot>
        ) : (
          <Button
            type="primary"
            label="Signup | Login"
            handleClick={() => {
              router.push('/login');
              return;
            }}
          ></Button>
        )}
      </div>
    </StyledNav>
  );
};

export default Nav;
