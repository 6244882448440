import React, { Children, useState, useEffect } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';

import Icon from './Icon';
import T from './Typography';

export const S = {};
S.Button = styled.button`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  background: ${props => props.theme.brandYellow};
  border: none;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.75);
  cursor: pointer;

  &:hover {
    background-color: #00ffff;
    transition: background-color 600ms;
  }

  &:disabled,
  [disabled] {
    opacity: 0.3;
    cursor: pointer;
  }
`;

S.SecondaryButton = styled(S.Button)`
  background: transparent;
  color: ${props => props.theme.brandYellow};
  box-shadow: none;
  padding: 8px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 200ms;
  }
`;

S.UtilityButton = styled(S.Button)`
  padding: 4px 8px;
  box-shadow: none;
`;

S.IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  transition: background 250ms;
  position: relative;

  &:hover {
    background-color: #242424;
    cursor: pointer;
  }
`;

S.MobileButton = styled.button`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.theme.brandYellow};
  position: fixed;
  bottom: 24px;
  right: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

S.Icon = styled.span`
  padding-right: 4px;
  padding-top: 4px;
`;

const Button = props => (
  <S.Button
    style={props.style}
    onClick={props.handleClick}
    disabled={props.setDisabled}
  >
    {props.icon ? (
      <S.Icon>
        <Icon size={15} color={'#212121'} icon={props.icon} />
      </S.Icon>
    ) : null}
    <T.ButtonText textBlack>{props.children}</T.ButtonText>
  </S.Button>
);

export const SecondaryButton = props => (
  <S.SecondaryButton
    style={{ ...props.style, color: props.color || '#FFE76B' }}
    onClick={props.handleClick}
    disabled={props.setDisabled}
  >
    {props.icon ? (
      <S.Icon>
        <Icon icon={props.icon} size={16} color={props.color || '#FFE76B'} />
      </S.Icon>
    ) : null}
    {props.children}
  </S.SecondaryButton>
);

export const MobileButton = props => (
  <S.MobileButton onClick={props.handleClick}>
    <Icon size={24} icon="thick-add" />
  </S.MobileButton>
);

export const UtilityButton = props => (
  <S.UtilityButton style={props.style} onClick={props.handleClick}>
    {props.icon ? (
      <S.Icon>
        <Icon icon={props.icon} size={15} color={'black'} />
      </S.Icon>
    ) : null}
    {props.children}
  </S.UtilityButton>
);

export const StyledTooltipTrigger = styled(Tooltip.Trigger)`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledTooltipContent = styled(Tooltip.Content)`
  background: black;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
`;

export const IconButton = props => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return (
      <button onClick={props.handleClick}>
        {props.icon ? (
          <Icon
            icon={props.icon}
            size={props.size === 'small' ? 10 : 20}
            color={props.color || 'white'}
          />
        ) : null}
      </button>
    );
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <StyledTooltipTrigger>
          <S.IconButton onClick={props.handleClick}>
            {props.icon ? (
              <Icon
                icon={props.icon}
                size={props.size === 'small' ? 10 : 20}
                color={props.color || 'white'}
              />
            ) : null}
          </S.IconButton>
        </StyledTooltipTrigger>
        <StyledTooltipContent>
          {props.name && props.name}
          <Tooltip.Arrow />
        </StyledTooltipContent>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const StyledNavButton = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.brandYellow};
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const NavButton = props => (
  <StyledNavButton
    style={{ ...props.style, color: props.color || 'white' }}
    target={props.target}
    href={props.href}
  >
    {props.icon ? (
      <Icon icon={props.icon} size={18} color={props.color || 'white'} />
    ) : null}
    {props.children}
  </StyledNavButton>
);

export default Button;
