import styled from 'styled-components';

export const ContainerStyle = styled.div`
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  text-align: center;
  width: 100%;

  .center {
    top: 24px;
  }

  .notification {
    background: #fff;
    transition: 0.3s ease;
    font-size: 14px;
    color: white;
    font-family: 'Roboto', sans-serif;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    display: inline-block;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    /* width: 300px; */
    /* height: 76px; */
    border-radius: 3px 3px 3px 3px;
    /* box-shadow: 0 0 10px #999; */
    color: #000;
    opacity: 0.9;
    padding: 16px;
    background-position: 15px;
    background-repeat: no-repeat;

    &::hover {
      box-shadow: 0 0 12px #fff;
      opacity: 1;
      cursor: pointer;
    }

    &-title {
      font-weight: 700;
      font-size: 20px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 6px;
      width: 300px;
      height: 18px;
    }

    &-message {
      margin: 0;
      text-align: left;
      height: 16px;
      margin-left: -1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-image {
      float: left;
      margin-right: 15px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    button {
      background: none;
      border: none;
      width: 32px;
      height: 32px;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }
`;
