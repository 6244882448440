import PropTypes from 'prop-types';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';

import { ContainerStyle } from './toast.style';
import Icon from '../Icon';
import { IconButton } from '../Button';
import U from '../UtilityStyle';

const renderToast = type => {
  switch (type) {
    case 'good':
      return { title: 'Success', bgColor: '#42B65B', textColor: 'white' };
    case 'bad':
      return { title: 'Error', bgColor: '#FF5323', textColor: 'black' };
    case 'warn':
      return { title: 'FYI', bgColor: '#ffe06a', textColor: 'black' };
    default:
      return { title: 'Info', bgColor: 'blue', textColor: 'white' };
  }
};

export const makeToastAppear = () => {};
export const makeToastDisappear = () => {};

const Toast = props => {
  const { type, details, position } = props;

  const { title, bgColor, textColor } = renderToast(type);

  const isToastActive = useStoreState(
    state => state.notifications.isToastActive,
  );

  const closeActiveToast = useStoreActions(
    actions => actions.notifications.closeActiveToast,
  );

  useEffect(() => {
    if (isToastActive) {
      setTimeout(closeActiveToast, 2500);
    }
  });

  return (
    <>
      <ContainerStyle className={`notification-container ${position}`}>
        <div
          data-testid="notification"
          className={`notification toast ${position}`}
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          <U.Flex>
            <div>
              <p data-testid="notificationTitle" className="notification-title">
                {title}
              </p>
              <p className="notification-message">{details}</p>
            </div>
            <IconButton
              icon="close"
              handleClick={() => closeActiveToast()}
              color={textColor}
            />
          </U.Flex>
        </div>
      </ContainerStyle>
    </>
  );
};

Toast.defaultProps = {
  position: 'center',
};

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  position: PropTypes.string,
};

export default Toast;
