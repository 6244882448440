import React from 'react';
import { useStoreState } from 'easy-peasy';

import { StoreModel } from '../store/model';
import { css } from '../styled-system/css';

import Nav from './Nav';
import Footer from './Footer';
import Toast from './toast/Toast';

const Layout = props => {
  const { isToastActive, activeToastType, activeToastDetails } =
    useStoreState<StoreModel>(state => state.notifications);

  return (
    <div
      className={css({
        backgroundColor: 'bg',
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDir: 'column',
        minHeight: '100vh',
      })}
    >
      <Nav />
      {isToastActive && (
        <Toast type={activeToastType} details={activeToastDetails} />
      )}
      <div
        className={css({
          margin: '0 auto',
          width: '90%',
          paddingBottom: '2rem',
          '@media only screen and (min-width: 768px)': {
            maxWidth: '744px',
            paddingBottom: '4rem',
          },
          '@media only screen and (min-width: 1024px)': {
            maxWidth: '960px',
          },
        })}
      >
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
